import cloneDeep from "lodash/cloneDeep";
import { env } from "../../env";

class DashboardCtrl {
	static instance;
	playerConfig = {};
	propertyAds = [];
	config = {};

	static getInstance() {
		if (DashboardCtrl.instance == null) {
			DashboardCtrl.instance = new DashboardCtrl();
		}

		return DashboardCtrl.instance;
	}

	getBenefitsEnabled = () => {
		const instance = DashboardCtrl.getInstance();
		return !!instance.config.enable_benefits;
	};

	getDefaultThemes = () => {
		const instance = DashboardCtrl.getInstance();
		return cloneDeep(instance.config.categories || []);
	};

	getHomePageSpotlight = () => {
		const instance = DashboardCtrl.getInstance();
		return cloneDeep(instance.config.homepage_spotlight);
	};

	getJustAddedMaxPageCount = () => {
		const instance = DashboardCtrl.getInstance();
		const { max_just_added_page_count } = instance.config;
		return max_just_added_page_count || null;
	};

	getMovieSceneSpotlightData = () => {
		const instance = DashboardCtrl.getInstance();
		const { movie_spotlight_id, scene_spotlight_id, scene_spotlight_movie_id } =
			instance.config;
		const data = {};
		if (movie_spotlight_id) {
			data.movieSpotlight = {
				movieId: movie_spotlight_id,
			};
		}
		if (scene_spotlight_id) {
			data.sceneSpotlight = {
				movieId: scene_spotlight_movie_id,
				sceneId: scene_spotlight_id
			};
		}

		return data;
	};

	getPlayerConfig = () => {
		const instance = DashboardCtrl.getInstance();
		return cloneDeep(instance.playerConfig);
	};

	getWhitelabelGeneralConfig = () => {
		const instance = DashboardCtrl.getInstance();
		const { preview_max_count = 1, preview_time_period = 24 } = instance.config;
		return { preview_max_count, preview_time_period };
	};

	setPlayerConfig = (config) => {
		const instance = DashboardCtrl.getInstance();
		if (config === null) {
			config = {
				config: [],
				configValues: {},
				id: env.REACT_APP_PROPERTY_ID,
				name: ''
			}
		}
		config.progressBarColors = {
			primary: config?.configValues?.playerColor || '#9f44b4',
			secondary: '#fff'
		};
		instance.playerConfig = config;
	};

	setWhiteLabelConfig = (config) => {
		const instance = DashboardCtrl.getInstance();
		instance.config = config;
	};
}

export const DashboardController = DashboardCtrl.getInstance();

import React, { useState, useEffect } from 'react';

import { RETURL, sessionExpires } from '../AvsPage.js';
import SupportService from '../../../components/SupportService/SupportService.js';

import { ROUTES } from "../../../services/navigation/navigation.routes";
import { useGtag } from '../../../hook/useGtag.js';

const AvsSuccess = () => {
    const [countdown, setCountdown] = useState(17);

    useGtag({
        data: {
            event: 'page_view',
            page_title: 'AVS Success',
            page_location: window.location.href,
            page_path: window.location.pathname,
            event_label: 'success_page_loaded',
            event_category: 'age_verification',
            verification_stage: 'success_page_loaded'
        }
    });

    let redirectUrl = localStorage.getItem(RETURL) || ROUTES.root;

    const onRemoveReturl = () => localStorage.removeItem(RETURL);

    useEffect(() => {
        if (countdown === 0) {
            if (redirectUrl) {
                window.location.href = redirectUrl;
                onRemoveReturl();
            } else {
                window.location.href = ROUTES.root;
            }
            return;
        }

        const timerId = setTimeout(() => {
            setCountdown(countdown - 1);
        }, 1000);

        return () => clearTimeout(timerId);

    }, [countdown, redirectUrl]);

    return (
        <>
            <div className="Content">
                <div className="Heading">AGE VERIFICATION COMPLETE</div>

                <p>Thank you for verifying your age with our partner Yoti.</p>
                <p><strong>Your status is: COMPLETE</strong></p>
                <p>Your session is valid until: {sessionExpires()}</p>
                <p><img src="https://spotlight.nakedcdn.com/nakedsword/img/yotilogo.jpg" width="138" height="63" alt="Yoti Logo" /></p>
                <p>If not automatically redirected in <span>{countdown}</span> seconds, please <strong><a href={redirectUrl} className="Link" onClick={onRemoveReturl}>click here</a></strong> to proceed.</p>
            </div>
            <SupportService />
        </>
    );
};

AvsSuccess.displayName = 'AvsSuccess';

export default AvsSuccess;
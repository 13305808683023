
export const getHtmlElementFromXml = async (fileName) => {
    const xmlFilePath = `http://localhost:3000/${fileName}.xml`;
    const xlsFilePath = `http://localhost:3000/${fileName}.xsl`;
    const newEl = await createAppendableXmlChild(xmlFilePath, xlsFilePath);
    return newEl;
};

const createAppendableXmlChild = async (xmlFile, xslFile) => {
    const { xml } = await loadXMLDoc(xmlFile);
    const { xml: xsl, xhttp } = await loadXMLDoc(xslFile);
    let newEl;
    if (window.ActiveXObject || xhttp.responseType === "msxml-document") {
        newEl = xml.transformNode(xsl);
    } else if (document.implementation && document.implementation.createDocument) {
        const xsltProcessor = new XSLTProcessor();
        xsltProcessor.importStylesheet(xsl);
        newEl = xsltProcessor.transformToFragment(xml, document);
    }
    return newEl;
};

const parseLocalData = (response) => {
    const { default: path } = response;
    return loadXMLDoc(path);
};

const loadXMLDoc = (filename) => {
    if (filename.startsWith("http://localhost")) {
        const split = filename.split("/");
        return import(`../../xmls/${split.pop()}`).then(parseLocalData);
    } else {
        return new Promise((resolve, reject) => {
            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", filename, true);

            xhttp.onload = () => {
                if (xhttp.status >= 200 && xhttp.status < 300) {
                    let xml = xhttp.responseXML;
                    if (!xml) {
                        var parser = new DOMParser();
                        xml = parser.parseFromString(xhttp.responseText, "application/xml");
                    }
                    resolve({
                        xml,
                        xhttp
                    });
                } else {
                    reject({
                        status: xhttp.status,
                        statusText: xhttp.statusText
                    });
                }
            };
            xhttp.onerror = () => {
                reject({
                    status: xhttp.status,
                    statusText: xhttp.statusText
                });
            };
            xhttp.send("");
        });
    }
};

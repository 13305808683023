import React, { Component } from 'react';

import './SupportService.scss';
import { UsersController } from "../../controllers/users-controller/users.controller";
import { isUserLoggedIn } from "../../services/token-service/token.service";
import { withTranslation } from 'react-i18next';

class SupportServiceCallNumbersOnly extends Component {
    subscriptions = {};

    constructor(props) {
        super(props);
        this.subscriptions.userStateChange = UsersController.userStateChange.subscribe(this.onUserStateChange);
    }

    componentWillUnmount() {
        for (const key in this.subscriptions) {
            this.subscriptions[key].unsubscribe();
        }
    }

    renderInfoElement = ({ info }) => {
        return (
            <div className="InfoOnlyWrap">
                {info}
            </div>
        );
    };

    callNumber = (number) => {
        return (
            <a
                className="DesignatorValue"
                href={`tel:${number.replace(/\./g, '')}`}
                target={"_self"}
            >{number}</a>
        );
    };

    renderCallContentInfo = () => {
        const {t} = this.props;
        const data = {
            info: (
                <div className="SupportServiceInfoContent">
                    <div className="Designator">U.S. (TOLL FREE)&nbsp;</div>
                    {this.callNumber(t('common:localSupportServiceCallNumber'))}
                    <div className="Designator">INTERNATIONAL&nbsp;</div>
                    {this.callNumber(t('common:internationalSupportServiceCallNumber'))}
                </div>
            )
        };
        return this.renderInfoElement(data);
    };



    onUserStateChange = (time) => {
        this.setState({
            userUpdated: time
        });
    };

    render() {
        const classes = ['SupportServiceInfoOnly'];
        if (isUserLoggedIn()) {
            classes.push('UserLoggedIn');
        }
        return (
            <div className={classes.join(' ')}>
                {this.renderCallContentInfo()}
            </div>
        );
    }
}

export default withTranslation()(SupportServiceCallNumbersOnly);
import React, {Component} from 'react';

import {ModalController} from '../../controllers/modal-controller/modal.controller';
import './ModalContainer.scss';

class ModalContainer extends Component {
  state = {
    data: []
  };
  subscription;
  startingZIndex = 2000;

  componentDidMount() {
    this.subscription = ModalController.modalAdded.subscribe(this.updateModals);
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  updateModals = (data) => {
    this.setState({
      data: data
    })
  };

  render() {
    const classes = ["ModalContainer"];
    if (this.state.data.length) {
      classes.push("Visible");
    }
    return (
      <div className={classes.join(' ')} style={{zIndex: this.startingZIndex}}>
        {this.state.data.map(({data: item, id}, index) => {
          return (
            <div style={{zIndex: this.startingZIndex + index + 2}} className="ModalWrapper" key={index}>
              {item}
            </div>
          );
        })}
      </div>
    );
  }
}

export default ModalContainer;


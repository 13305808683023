import React, { useEffect, Suspense } from 'react';
import { Helmet } from "react-helmet";
import '@fortawesome/fontawesome-free/css/all.min.css';
import LoadingMask, { LoadingText } from "./components/LoadingMask/LoadingMask";
import MainDashboard from './pages/MainDashBoard/MainDashboard';
import { MiniPlayerContainer } from "@falconstudios/ns-player";
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';

import Div100vh from 'react-div-100vh';
import { handleTokenRefresh } from './services/token-service/token.service';
import { env } from './env';
import './styles/main.scss';
import './App.scss';
import './overrides/overrides.css';
import ImagesLightBoxStyle from './components/ImagesLighbox/ImagesLightBoxStyle';
import { clarity } from 'react-microsoft-clarity';

function App() {
  useEffect(() => {
    // check for expiration cleanup if expired
    handleTokenRefresh();

    const hostname = window.location.hostname;
    console.log('Detected hostname:', hostname);

    if (hostname === "www.nakedsword.com") {
      console.log("Initializing Clarity for hostname:", hostname);
      clarity.init('lz5fdkfj52'); // clarity ID
    } else {
      console.log("Clarity not initialized due to hostname mismatch.");
    }
  }, []);


  const reactappsitename = env.REACT_APP_SITE_NAME.replace(/\./g, '');

  return (
    <div className={`${reactappsitename}`}>
      <Div100vh>
        <div className="App">
          <Helmet>
            <title>{env.REACT_APP_SITE_TITLE}</title>
            <meta name="description" content={env.REACT_APP_SITE_DESCRIPTION} />
          </Helmet>
          <Suspense fallback={<LoadingText />}>
            <MainDashboard />
          </Suspense>
          <MiniPlayerContainer />
          <LoadingMask />
          <ImagesLightBoxStyle />
        </div>
      </Div100vh>
    </div>
  );
}

export default App;
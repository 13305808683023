import React, { useRef, useEffect } from 'react';
import { getHtmlElementFromXml } from '../../../services/util-service/parse-xml.service';

const AvsXmlPages = ({ xmlPageKey }) => {
    const contentRef = useRef();

    useEffect(() => {
        const setContent = async () => {
            if (!xmlPageKey) return;

            const data = await getHtmlElementFromXml(xmlPageKey);
            const { current } = contentRef;
            if (current) {
                current.appendChild(data);
            }
        };
        setContent();
    }, [xmlPageKey]);

    return (
        <div className='AvsXmlPages'>
            <div ref={contentRef}></div>
        </div>
    )
};

AvsXmlPages.displayName = 'AvsXmlPages';

export default AvsXmlPages;
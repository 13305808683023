import React from 'react';
import { Link } from "@reach/router";

import SupportService from '../../../components/SupportService/SupportService.js';

import { ROUTES } from "../../../services/navigation/navigation.routes";
import { useGtag } from '../../../hook/useGtag.js';

const AvsError = () => {
	useGtag({
		data: {
			event: 'page_view',
			page_title: 'AVS Error',
			page_location: window.location.href,
			page_path: window.location.pathname,
			event_label: 'error_page_loaded',
			event_category: 'age_verification',
			verification_stage: 'error_page_loaded'
		}
	});

	return (
		<>
			<div className="Content">
				<div className="Heading">AGE VERIFICATION ERROR</div>

				<p>Sorry we encountered an error during your verification. Please
					<Link className="Link" to={`/${ROUTES.ageVerificationLanding}`}> try again</Link>.
				</p>
			</div>
			<SupportService />
		</>
	);
};

AvsError.displayName = 'AvsError';

export default AvsError;
import axios from 'axios';
import { env } from '../../env';

const urls = {
  ads: '/frontend/ads_config',
  studios: '/frontend/studios/feed',
  whiteLabelConfig: '/frontend/whitelabel_config'
};

export const getStudios = () => {
  return axios.get(urls.studios);
};

export const getWhiteLabelConfig = () => {
  const propertyId = env.REACT_APP_PROPERTY_ID;
  return axios.get(`${urls.whiteLabelConfig}/${propertyId}/get`, {
    params: {
      id: propertyId
    }
  });
}; 
